import React from "react";
import Layout from "../components/layout";
import Title from "../components/title";
import Cta from "../components/cta";

import "../styles/object/component/typography.scss";
import "../styles/object/project/successfullysent.scss";
import define from "../config/define";

export default () => (
    <Layout title="Successfully Sent">
        <Title top="SUCCESSFULLY SENT" topSub="送信完了" />
        <main className="l-main">
            <section className="success">
                <div className="c-heading">
                    <h4 className="heading_main">MESSAGE HAS BEEN SENT</h4>
                    <h5 className="c-heading_sub">お問い合わせを送信しました</h5>
                </div>
                <div className="container">
                    <div>
                        <p>この度はお問い合わせをいただき誠にありがとうございます。</p>
                        <p>お問い合わせいただきました内容を確認の上、担当者よりご連絡をさせていただきます。</p>
                        <p>いましばらくお待ちくださいますよう、よろしくお願い申し上げます。</p>
                    </div>
                </div>
                <div className="container">
                    <div >
                        <p>なお、しばらくたっても返信、返答がない場合には、お客様が入力されたメールアドレスに誤りがある場合がございます。</p>
                        <p>その場合には、大変お手数ですが、再度送信していただくか、お電話（{define.corp.tel}）にてご連絡、ご確認をいただけますよう
                            <br className="break"></br>お願い申し上げます。</p>
                    </div>
                </div>
            </section>
            <Cta />
        </main>
    </Layout>
);
